const dictionaries = {
  en: () =>
    import('../dictionaries/en').then((module) => module.default as Dictionary),
  sv: () =>
    import('../dictionaries/sv').then((module) => module.default as Dictionary),
};

export const getDictionary = async (
  locale: keyof typeof dictionaries
): Promise<Dictionary> => {
  const loader = dictionaries[locale];
  if (!loader) {
    console.error(`Dictionary for locale '${locale}' not found`);
    const loader = dictionaries.sv;
    return await loader();
  }
  return await loader();
};
