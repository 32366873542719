import React from 'react';
import clsx from 'clsx';

interface FlagProps extends React.SVGAttributes<SVGSVGElement> {
  size?: string;
  className?: string;
}

const SwedenFlag: React.FC<FlagProps> = ({
  size = '40px',
  className,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      className={clsx('icon', className)}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        fill="#006AA7"
        d="M15.5 31H32c2.209 0 4-1.791 4-4.5v-6H15.5V31zM32 5H15.5v10.5H36V9a4 4 0 0 0-4-4zM10.5 5H4a4 4 0 0 0-4 3.997V15.5h10.5V5zM0 20.5v6.004C.002 29.211 1.792 31 4 31h6.5V20.5H0z"
      ></path>
      <path
        fill="#FECC00"
        d="M15.5 5h-5v10.5H0v5h10.5V31h5V20.5H36v-5H15.5z"
      ></path>
    </svg>
  );
};

export default SwedenFlag;
