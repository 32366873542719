'use client';

import { useCallback } from 'react';
import { useTranslation } from '~/components/translate';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '~/shadcn/ui/dropdown-menu';
import SwedenFlag from '~/shadcn/icons/logo-sweden';
import UKFlag from '~/shadcn/icons/loog-united-kingdom';
import { Button } from '~/shadcn/ui/button';

// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Svenska',
    value: 'sv',
    icon: <SwedenFlag size="28px" />,
  },
  {
    label: 'English',
    value: 'en',
    icon: <UKFlag size="28px" />,
  },
];

export default function LanguagePopover() {
  const { setLocale, lang } = useTranslation();

  const currentLang = allLangs.find((langObj) => langObj.value === lang);

  const handleChangeLang = useCallback(
    (langValue: (typeof allLangs)[number]['value']) => {
      setLocale(langValue as AppLocale);
    },
    [setLocale]
  );

  if (!currentLang) return null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="text-muted-foreground rounded-full"
        >
          {currentLang.icon}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-40">
        {allLangs.map((option) => (
          <DropdownMenuItem
            key={option.value}
            onClick={() => handleChangeLang(option.value)}
            className={
              option.value === currentLang.value
                ? 'bg-gray-200 dark:bg-gray-700'
                : ''
            }
          >
            {option.icon}
            <span className="ml-2">{option.label}</span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
